<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" fixed-tabs height="47" @change="getAllTasks">
        <v-tab :href="'#tab-1'">
          <b>المهام المسندة من قبل الأخرين</b>
        </v-tab>
        <v-tab :href="'#tab-2'">
          <b>المهام المسندة من قبلي</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-1'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <task-table
                  v-if="tasks.length > 0"
                  @reload="getAllTasks"
                  :tasks="tasks"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item :value="'tab-2'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <task-table
                  v-if="tasks.length > 0"
                  @reload="getAllTasks"
                  :tasks="tasks"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import TaskTable from "./TaskTable.vue";
export default {
  components: {
    "task-table": TaskTable
  },
  data() {
    return {
      tab: "tab-1",
      tasks: []
    };
  },
  methods: {
    async getAllTasks() {
      this.loading = true;
      let url = "task/GetAllTasksByCreatorRole?user=";
      if (this.tab == "tab-1") {
        url = "task/GetAllTasksByCreatorRole?user=";
      }
      if (this.tab == "tab-2") {
        url = "task/GetAllTasksByCreator?creator=";
      }
      this.tasks = [];
      await this.ApiService.get(url + this.user.id)
        .then(res => {
          this.tasks = [];
          this.tasks = res.data.responseData;
          this.loading = false;
        })
        .catch(() => {});
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.getAllTasks();
  }
};
</script>
<style scoped>
b {
  font-size: 14px;
}
</style>

<template>
  <div>
    <v-divider />
    <v-data-table
      :search="search"
      :loading="loading"
      :headers="headers"
      :items="allTask"
      sort-by="name"
      class="elevation-1 mt-3"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-layout row wrap>
            <v-flex xs8 md8>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="بحث"
                single-line
                hide-details
              >
              </v-text-field>
            </v-flex>
            <v-flex xs4 md4>
              <filter-component
                @filterDate="setFilteredDate($event)"
                :path="'Filter/AllTask?user=' + user.id"
              />
            </v-flex>
          </v-layout>
        </v-toolbar>
        <v-dialog v-model="dialogDelete" max-width="300px">
          <v-card>
            <v-card-title class="headline">هل أنت متأكد من الحذف؟</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text-color="white"
                text
                @click="closeDelete"
                >إلغاء</v-btn
              >
              <v-btn
                color="gray"
                text-color="white"
                text
                @click="deleteItemConfirm"
                >موافق</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.typeId="{ item }">
        {{
          item.typeId == 1
            ? "زيارة"
            : item.typeId == 2
            ? "تنبيه"
            : item.typeId == 3
            ? "طلب معلومات "
            : "-"
        }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{ createdDate(item.startDate) }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{ createdDate(item.endDate) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          color="green"
          text-color="white"
          small
          class="mr-2"
          @click="
            $router.push({ name: 'show-task', params: { taskId: item.idtask } })
          "
        >
          mdi-eye
        </v-icon>
        <v-icon
          color="blue"
          v-if="role == 'Admin'"
          text-color="white"
          small
          class="mr-2"
          @click="
            $router.push({
              name: 'edit-task',
              params: { task: item, taskId: item.idtask }
            })
          "
        >
          mdi-pencil
        </v-icon>
        <v-icon
          color="red"
          v-if="role == 'Admin'"
          text-color="white"
          small
          @click="deleteItem(item.idtask)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        لا توجد بيانات
      </template>
    </v-data-table>
  </div>
</template>

<script>
import filterCom from "../shared/filterData.vue";
export default {
  props: ["tasks"],
  components: {
    "filter-component": filterCom
  },
  data() {
    return {
      search: "",
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: "رقم المهمة",
          align: "center",
          sortable: true,
          value: "idtask"
        },
        {
          text: "اسم المهمة",
          align: "center",
          sortable: true,
          value: "name"
        },
        {
          text: "نوع المهمة",
          align: "center",
          sortable: true,
          value: "typeId"
        },
        {
          text: "تاريخ البداية",
          align: "center",
          sortable: true,
          value: "startDate"
        },
        {
          text: "تاريخ النهاية",
          align: "center",
          sortable: false,
          value: "endDate"
        },
        { text: "خيارات", align: "center", value: "actions", sortable: false }
      ],
      dialog: false,
      page: 1,
      totalItems: 0,
      selectedExternalType: {
        value: 0
      },
      deletedItem: "",
      allTask: []
    };
  },
  methods: {
    setFilteredDate(event) {
      this.tasks = [];
      this.allTask = event;
    },
    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.ApiService.delete("task/DeleteTask/" + this.deletedItem)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.$emit("reload");
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.deletedItem = null;
      this.dialogDelete = false;
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    this.allTask = this.tasks;
  }
};
</script>

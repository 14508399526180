<template>
  <v-row justify="center">
    <v-dialog v-model="filterDialog" persistent max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn
          small
          style="float:left;background:gray;color:white;"
          v-on="on"
          fab
        >
          <v-icon dark>
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title style="background:gray;color:black;">
          <v-row align="center" no-gutters>
            <v-col
              ><v-btn color="blue darken-1" text @click="closeFilterDialog"
                ><b>إلغاء</b></v-btn
              ></v-col
            >
            <v-col><b class="headline">تصفية البحث</b></v-col>
            <v-col
              ><v-btn color="blue darken-1" text @click="resetDialog"
                ><b>إعادة تعيين</b></v-btn
              ></v-col
            >
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-flex xs12 class="mt-5">
                  <strong>نوع المهمة</strong>
                  <v-radio-group
                    @change="addTofilter('type')"
                    v-model="filter.type"
                    row
                  >
                    <v-radio label="لا يوجد" value="0"></v-radio>
                    <v-radio label="زيارة" value="1"></v-radio>
                    <v-radio label="تنبيه" value="2"></v-radio>
                    <v-radio label="طلب معلومات" value="3"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12>
                  <strong>حالة المهمة</strong>
                  <v-radio-group
                    @change="addTofilter('status')"
                    v-model="filter.status"
                    row
                  >
                    <v-radio label="لا يوجد" value="0"></v-radio>
                    <v-radio label="موكلة" value="4"></v-radio>
                    <v-radio label="بالانتظار" value="5"></v-radio>
                    <v-radio label="منجزة" value="3"></v-radio>
                    <v-radio label="قيد التقدم" value="2"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12>
                  <strong>الجهة الخارجية</strong>
                  <v-radio-group
                    @change="addTofilter('external')"
                    v-model="filter.external"
                    row
                  >
                    <v-radio label="لا يوجد" value="0"></v-radio>
                    <v-radio label="مصنع" value="1"></v-radio>
                    <v-radio label="مشروع" value="2"></v-radio>
                    <v-radio label="مقاول" value="3"></v-radio>
                    <v-radio label="مباسط" value="4"></v-radio>
                    <v-radio label="مواقف" value="5"></v-radio>
                    <v-radio label="ناقل" value="6"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs12>
                  <strong>تكرار المهمة</strong>
                  <v-select
                    @change="addTofilter('repeat')"
                    v-model="selecyedTaskRepetition"
                    :items="TaskRepetition"
                    item-text="item"
                    item-value="value"
                    label="تكرار المهمة"
                    persistent-hint
                    return-object
                    required
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <strong>اسم المستخدم الذي أنشأ المهمة</strong>
                  <v-autocomplete
                    @change="addTofilter('user')"
                    :items="users"
                    v-model="ResponsibleId"
                    :filter="customFilter"
                    color="white"
                    item-text="item"
                    label="اسم المستخدم الذي أنشأ المهمة"
                    :rules="[v => v.value != '0' || 'الاختيار غير مقبول']"
                    item-value="value"
                    return-object
                    required
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12>
                  <strong>تاريخ انتهاء المهمة بين</strong>
                  <v-layout row-wrap>
                    <v-flex xs12 md6>
                      <template>
                        <v-flex xs12>
                          <v-dialog
                            ref="dialog2"
                            v-model="modal2"
                            :return-value.sync="date2"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="startDate"
                                label="تاريخ البداية"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              @change="addTofilter('startDate')"
                              v-model="startDate"
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal2 = false"
                              >
                                إغلاق
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog2.save(date2)"
                              >
                                حفظ
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                      </template>
                    </v-flex>
                    <v-flex xs12 md6>
                      <template>
                        <v-flex xs12>
                          <v-dialog
                            ref="dialog"
                            v-model="modal"
                            :return-value.sync="date"
                            persistent
                            width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="endDate"
                                label="تاريخ النهاية"
                                prepend-icon="mdi-calendar"
                                :rules="EndDateRules"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              @change="addTofilter('endDate')"
                              v-model="endDate"
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                              >
                                إغلاق
                              </v-btn>
                              <v-btn
                                text
                                color="primary"
                                @click="$refs.dialog.save(date)"
                              >
                                حفظ
                              </v-btn>
                            </v-date-picker>
                          </v-dialog>
                        </v-flex>
                      </template>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="getFilteredData" style="background:gray;" dark>
            <b>حفظ</b>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["path"],
  data() {
    return {
      EndDateRules: [
        () => this.testDate || "تاريخ النهاية لا يمكن أن يسبق  تاريخ البداية"
      ],
      testDate: false,
      selecyedTaskRepetition: 0,
      modal: false,
      modal2: false,
      date: new Date().toISOString().substr(0, 10),
      date2: new Date().toISOString().substr(0, 10),
      ResponsibleId: "",
      users: [{ item: "اختر المستخدم الموكلة له المهمة", value: "0" }],
      valid: true,
      filterDialog: false,
      taskStatus: [
        { value: "0", item: "اختر حالة المهمة" },
        { value: "1", item: "كل المهام " },
        { value: "2", item: "مهام جديدة" },
        { value: "3", item: "مهام منجزة" },
        { value: "4", item: "مهام بانتظار المراجعة" },
        { value: "5", item: "مهام قيد التقدم" }
      ],
      TaskRepetition: [
        { value: "0", item: "لا يوجد" },
        { value: "d", item: "يومي" },
        { value: "w", item: "إسبوعي" },
        { value: "m", item: "شهري" },
        { value: "y", item: "سنوي" }
      ],
      filter: {
        type: 0,
        status: 0,
        external: 0
      },
      startDate: new Date().toISOString().substr(0, 10),
      endDate: new Date().toISOString().substr(0, 10),
      repeat: 0,
      user: "",
      filters: {}
    };
  },
  methods: {
    closeFilterDialog() {
      this.filterDialog = false;
      this.resetDialog();
    },
    resetDialog() {
      this.filter = {
        type: 0,
        status: 0,
        external: 0
      };
      (this.startDate = new Date().toISOString().substr(0, 10)),
        (this.endDate = new Date().toISOString().substr(0, 10)),
        (this.filters = {});
      this.selecyedTaskRepetition = "";
      this.ResponsibleId = "";
    },
    async getUsers() {
      this.users = [{ item: "لا يوجد", value: "0" }];
      await this.ApiService.get("auth/listusers")
        .then(res => {
          var allUsers = res.data;
          allUsers.forEach(el => {
            this.users.push({ value: el.user.id, item: el.user.fullName });
          });
        })
        .catch(() => {});
    },
    addTofilter(key) {
      if (key == "type" || key == "status" || key == "external") {
        if (this.filter[key] == 0) {
          if (this.filters[key]) delete this.filters[key];
        } else {
          this.filters[key] = this.filter[key];
        }
      }
      if (key == "repeat") {
        if (this.selecyedTaskRepetition.value == 0) {
          if (this.filters[key]) delete this.filters[key];
        } else {
          this.filters[key] = this.selecyedTaskRepetition.value;
        }
      }
      if (key == "user") {
        if (this.ResponsibleId.value == 0) {
          if (this.filters[key]) delete this.filters[key];
        } else {
          this.filters[key] = this.ResponsibleId.value;
        }
      }
      if (key == "startDate") {
        if (this.endDate > this.startDate) {
          this.testDate = false;
        } else {
          this.filters[key] = this.startDate;
          this.filters["endDate"] = this.endDate;
        }
      }
      if (key == "endDate") {
        if (this.endDate > this.startDate) {
          this.testDate = false;
        } else {
          this.filters["startDate"] = this.startDate;
          this.filters[key] = this.endDate;
        }
      }
    },
    async getFilteredData() {
      if (Object.keys(this.filters).length == 0) {
        this.closeFilterDialog();
      } else {
        await this.ApiService.post(
          this.path + "&isPagination=false",
          this.filters
        )
          .then(res => {
            let dataFilter = [];
            let data = res.data.responseData;
            data.forEach(el => {
              if (el != null) {
                dataFilter.push(el);
              }
            });
            this.$emit("filterDate", dataFilter);
          })
          .catch(() => {});
        this.closeFilterDialog();
      }
    },
    customFilter(item, queryText) {
      const textOne = item.item.toLowerCase();
      const searchText = queryText.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    }
  },
  created() {
    this.getUsers();
  }
};
</script>
